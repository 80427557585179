<template>
	<w-layout column mt-3>
		<w-flex>
			<ConnectionItem v-for="connection in connections" :key="connection.id" :value="connection" />
		</w-flex>
	</w-layout>
</template>

<script>
import { mapState } from 'vuex'
import BillScrapperModuleGuard from '@/mixins/ModulesGuards/BillScrapper/BillScrapperModuleGuard'

export default {
	name: 'ConnectionsList',
	components: {
		ConnectionItem: () => ({
			component: import('@/components/BillScrapper/Automations/ConnectionItem')
		})
	},
	mixins: [BillScrapperModuleGuard],
	data: function () {
		return {
			connections: []
		}
	},
	computed: {
		...mapState({
			company: state => state.company.selected
		})
	},
	watch: {
		company: {
			handler: function (val) {
				if (val && val.id) {
					this.getConnections()
				}
			}
		}
	},
	mounted: function () {
		this.getConnections()
	},
	methods: {
		getModuleEventsActionsMapping: function () {
			return [
				{ event: this.events.CONNECTION_CREATED, action: this.addConnection },
				{ event: this.events.CONNECTION_UPDATED, action: this.updateConnection },
				{ event: this.events.CONNECTION_DELETED, action: this.removeConnection }
			]
		},
		addConnection: function (connection) {
			const index = this.connections.findIndex(c => c.id === connection.id)
			if (!isNaN(index)) {
				this.connections.push(connection)
				this.sortedConnections()
			}
		},
		getConnections: function () {
			this.connections.clear()
			return this.service.getConnections().then(connectionsList => {
				this.connections = connectionsList
				this.sortedConnections()
			})
		},
		sortedConnections: function () {
			const collator = new Intl.Collator(this.$i18n.locale)

			this.connections.sort((connectionA, connectionB) => {
				return collator.compare(connectionA.provider.name, connectionB.provider.name)
			})
		},
		removeConnection: function (connectionId) {
			const index = this.connections.findIndex(connection => connection.id === connectionId)
			if (!isNaN(index)) {
				this.connections.splice(index, 1)
			}
			this.sortedConnections()
		},
		updateConnection: function (connection) {
			this.connections.forEach(c => {
				if (connection.id === c.id) {
					Object.assign(c, connection)
				}
			})
		}
	}
}
</script>
